import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, TextField, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import rest from "../../rest"
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { MySnackbar } from '../mySnackbar/MySnackbar';

const subtitle = {
    fontWeight: "bold"
};

const OngletCompteUser = ({ readOnly, idBenevole }) => {
    const [loading, setLoading] = useState(false);
    const [accountInfos, setAccountInfos] = useState([])
    const [compteActuel, setCompteActuel] = useState("")
    const [vCompteUser, setCompteUser] = useState("")
    const [vPwdUser, setPwdUser] = useState("")
    const [pwdOk, setPwdOk] = useState(false)
    const [emailOk, setEmailOk] = useState(false)
    const [droitAdmin, setDroitAdmin] = useState(false)
    const [gestGroupe, setGestGroupe] = useState(false)
    const [snack, setSnack] = useState({});
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        var paramsData = {
            'vIdPers': idBenevole,
        }
        rest.post('/benevols/GetAccount.json', paramsData).then(function (response) {
            setAccountInfos(response.data)
            setCompteUser(response.data.email)
            setCompteActuel(response.data.email)
            setDroitAdmin(response.data.administrator)
            setGestGroupe(response.data.gestionnaireDeGroupe)
        }, (error) => {
            console.log(error);
        })
    }, [idBenevole, refresh])


    const handleEmailChange = (event) => {
        setCompteUser(event.target.value);
        if (validateEmail(vCompteUser)) {
            setEmailOk(false)
        } else {
            setEmailOk(true)
        }
    };

    const handlePwdChange = (event) => {
        setPwdUser(event.target.value);
        if (vPwdUser.length < 5) {
            setPwdOk(true)
        } else {
            setPwdOk(false)
        }
    };

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(email) && email !== "");
    }

    const saveCptUser = () => {
        if (validateEmail(vCompteUser)) {
            var paramsData = {
                'Ancien': compteActuel,
                'Mdp': vPwdUser,
                'Compte': vCompteUser,
                'vDroitAdmin': droitAdmin,
                'vDroitGestGr': gestGroupe
            }
            setLoading(true)
            rest.post('/compte/gestion', encodeURIComponent(JSON.stringify(paramsData))).then(function (response) {
                if (response.data.erreur === "ok") {
                    setSnack({ message: "Modifications enregistrées", show: true, severity: "success" })
                    setRefresh((prev) => !prev)
                } else {
                    setSnack({ message: response.data.erreur, show: true, severity: "error" })
                }
                setLoading(false)
            })
        }
    }


    return (
        <div style={{ width: "100vw", marginInline: "15vw" }} >
            <LoadingIndicator open={loading} />
            <Grid container  >
                <Grid item xs={8}  >
                    <MySnackbar params={snack} />
                    <List component="nav">
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Compte utilisateur"} secondary={
                                <Typography>{accountInfos.email}</Typography>
                            } />
                        </ListItem>
                        <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Création du compte"} secondary={
                                <Typography>{accountInfos.creationDate}</Typography>
                            } />
                        </ListItem>
                        <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Dernière connexion"} secondary={
                                <Typography>{accountInfos.lastConnection}</Typography>
                            } />
                        </ListItem>
                    </List>
                    {!readOnly &&
                        <Grid>
                            <span >
                                <TextField
                                    error={emailOk}
                                    id="vCompteUser"
                                    name="vCompteUser"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    required={true}
                                    value={vCompteUser}
                                    onChange={handleEmailChange}
                                /><br />
                            </span> <br />
                            <span >
                                <TextField
                                    error={pwdOk}
                                    id="vComptePwd"
                                    name="vComptePwd"
                                    label="Mot de passe"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    required={false}
                                    value={vPwdUser}
                                    onChange={handlePwdChange}
                                /><br />
                            </span>
                            <small>Le mot de passe restera inchangé si le champ reste vide</small>

                            <br /><br /><br />

                            <Grid style={{ fontSize: 20, textAlign: "center " }}>
                                <span >Droits : </span>  <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={droitAdmin}
                                            onChange={(e) => setDroitAdmin(e.target.checked)}
                                            name="droitAdmin"
                                            color="primary"
                                        />
                                    }
                                    label="Administrateur"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={gestGroupe}
                                            onChange={(e) => setGestGroupe(e.target.checked)}
                                            name="droitGestGroupe"
                                            color="primary"
                                        />
                                    }

                                    label="Gestionnaire de groupe"
                                />

                                <br /><br /><br />
                                <Button variant="contained" color="primary" style={{ width: "67vw" }} onClick={saveCptUser}>Enregistrer</Button>
                            </Grid></Grid>}
                </Grid>
            </Grid>
            <br />
        </div>
    )
}

export default OngletCompteUser

