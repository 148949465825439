import React, { useState } from 'react'
import { useStyleCard } from './Cards.css';
import { Avatar, Card, CardActions, CardActionArea, CardHeader, CardContent, Checkbox, FormControlLabel, Grid, Popover, Typography, Button } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import rest from '../../rest';
import { MySnackbar } from '../mySnackbar/MySnackbar'
import { LazyLoadImage } from "react-lazy-load-image-component";
import GetRandomKey from '../general/genereRandomKey';

const BenevolesCards = (props) => {
  const classesGlobal = useStylesGlobal();
  const classes = useStyleCard();
  const manifInfoComple = props.manif.manifInfosProfil.InfosComplementaires;
  const benAPlacer = props.benAPlacer;
  const benSelected = props.benSelected;
  const setBenSelected = props.setBenSelected;
  /* const rendreBenWishlistTraitee = props.rendreBenWishlistTraitee */
  const setRendreBenWishlistTraitee = props.setRendreBenWishlistTraitee
  /*  const benWishlistTraiteeToUpdate = props.benWishlistTraiteeToUpdate */
  const setBenWishlistTraiteeToUpdate = props.setBenWishlistTraiteeToUpdate
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null)
  const [anchorElStatut, setAnchorElStatut] = useState(null);
  const [openedPopoverIdStatut, setOpenedPopoverIdStatut] = useState(null)
  const [openTrancheChoice, setOpenTrancheChoice] = useState(false);
  const [openActivityChoice, setOpenActivityChoice] = useState(false);
  const [infosTranchesChoice, setInfosTranchesChoice] = useState({});
  const [checkedTranches, setCheckedTranches] = useState([]);
  const [snack, setSnack] = useState({});
  const [selectedActivityChoice, setSelectedActivityChoice] = useState(null);
  const [listActivityChoice, setListActivityChoice] = useState([]);
  const [currentActChoice, setCurrentActChoice] = useState("")
  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId)
  }

  const handlePopoverClose = (e) => {
    setAnchorEl(null);
    setOpenedPopoverId(null)
  }

  const handlePopoverStatutOpen = (event, popoverId) => {
    setAnchorElStatut(event.currentTarget);
    setOpenedPopoverIdStatut(popoverId)
  }

  const handlePopoverStatutClose = (e) => {
    setAnchorElStatut(null);
    setOpenedPopoverIdStatut(null)
  }

  const handleChange = (event) => {
    setBenWishlistTraiteeToUpdate(event.target.id);
    setRendreBenWishlistTraitee(event.target.checked);
  };

  const handleBenClick = (e) => {
    if (benSelected === 0)
      setBenSelected(parseInt(e.target.offsetParent.id));
    else
      setBenSelected(0);
  }

  const handleBenDoubleClick = (e) => {
    setBenSelected(0);
  }

  const handleAffectation = (persID, dispo) => {
    setSelectedActivityChoice(null)
    rest.post("/benevols/activityResp.json", { "persID": persID })
      .then((response) => {
        setListActivityChoice(response.data)
        setOpenActivityChoice(true)
        setInfosTranchesChoice({ persID: persID, dispo: [], action: "accepted" })
      })
  }

  const handleAcceptRefused = (persID, dispo, action, actID, choixAct) => {
    if (action === "accepted") {
      let tmp = [];
      for (let index = 0; index < dispo.length; index++) {
        tmp.push(true)
      }
      setCurrentActChoice(choixAct[0].nom)
      setCheckedTranches(tmp)
      setInfosTranchesChoice({ persID: persID, dispo: dispo, action: action })
      setOpenTrancheChoice(true)
    } else {
      rest.post("/benevols/attributionPlacement.json", { persID: persID, dispo: JSON.stringify(dispo), action: action, actID: actID })
        .then((response) => {
          props.setRefreshBenList((prev) => !prev)
        })
    }

  }


  const handleCloseTrancheChoice = () => {
    setOpenTrancheChoice(false)
  }

  const handleCloseActivityChoice = () => {
    setOpenActivityChoice(false)
  }

  const handleValidateActivityChoice = () => {

    setOpenActivityChoice(false)
    setOpenTrancheChoice(true)
  }


  const handleValidateTrancheChoice = () => {
    let finalDispo = [];
    for (let index = 0; index < infosTranchesChoice.dispo.length; index++) {
      const element = infosTranchesChoice.dispo[index];
      if (checkedTranches[index]) {
        finalDispo.push(element)
      }
    }
    if (finalDispo.length > 0) {
      rest.post("/benevols/attributionPlacement.json", { persID: infosTranchesChoice.persID, dispo: JSON.stringify(finalDispo), action: infosTranchesChoice.action })
        .then((response) => {
          if (response.data.error) {
            setSnack({ message: response.data.message, show: true, severity: "error" })
          } else {
            setSnack({ message: response.data.message, show: true, severity: "success" })
            setOpenTrancheChoice(false)
            props.setRefreshBenList((prev) => !prev)
          }
        })
    } else {
      setSnack({ message: "Veuillez sélectionner au minimum une tranche horaire.", show: true, severity: "error" })
    }

  }

  const handleChangeTrancheAttr = (index) => {
    setCheckedTranches((prev) => {
      let copy = JSON.parse(JSON.stringify(prev))
      copy[index] = !copy[index]
      return copy
    })
  }

  const handleChangeSelectedActivity = (event) => {
    rest.post("/benevols/concordanceDispoTranche.json", { actID: event.target.value, persID: infosTranchesChoice.persID })
      .then((response) => {
        let tmp = [];
        for (let index = 0; index < response.data.length; index++) {
          tmp.push(true)
        }
        const nomActivite = listActivityChoice.find((item) => item.ID === event.target.value)?.Nom
        setCheckedTranches(tmp)
        setInfosTranchesChoice({ persID: infosTranchesChoice.persID, dispo: response.data, action: "accepted" });
        setCurrentActChoice(nomActivite);
      })
    setSelectedActivityChoice(event.target.value);
  };

  return (
    <>
      <MySnackbar params={snack} />
      <Dialog open={openActivityChoice} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Choix activité</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez sélectionner une activité d'affectation.
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel id="activityChoice">Activité</InputLabel>
            <Select
              labelId="activityChoice"
              id="activityChoice"
              value={selectedActivityChoice}
              onChange={handleChangeSelectedActivity}
              style={{ width: "250px" }}
            >
              {listActivityChoice.map((itm, idx) => (
                <MenuItem key={GetRandomKey(10)} value={itm.ID}>{itm.Nom}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseActivityChoice} className={classesGlobal.buttonFermer}>
            Annuler
          </Button>
          <Button onClick={handleValidateActivityChoice} className={classesGlobal.successBtn} >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTrancheChoice} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Dates souhaitées ({currentActChoice})</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez valider les tranches horaires d'attribution.
          </DialogContentText>
          <FormControl component="fieldset" className={classes.formControl}>
            {infosTranchesChoice.dispo && infosTranchesChoice.dispo.map((itm, idx) => (
              <FormControlLabel
                key={GetRandomKey(8)}
                control={<Checkbox checked={checkedTranches[idx]} onChange={_ => handleChangeTrancheAttr(idx)} />}
                label={itm.date + " : " + itm.periode}
              />
            ))}
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTrancheChoice} className={classesGlobal.buttonFermer}>
            Annuler
          </Button>
          <Button onClick={handleValidateTrancheChoice} className={classesGlobal.successBtn} >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      {benAPlacer.length > 0 ?
        benAPlacer.map((item, index) => {
          let statutPerson = '';
          const itemIndex = item.link_Wishlist_Person[0].Contenu?.ChoixActivitesNoFiltred?.findIndex(itm => itm.id === item.link_Wishlist_Person[0].Contenu.ChoixActivites[0].id);
          //console.log(item.Firstname + " " + item.Name, item.link_Wishlist_Person[0].Contenu, itemIndex, item);
          if (item.link_PersonTranchesTempo_Person.length === 0) {
            statutPerson = props.statutOptions.find((item) => item.value === -4)?.text;
          } else {
            statutPerson = item.link_PersonTranchesTempo_Person && item.link_PersonTranchesTempo_Person.length > 0 && item.link_PersonTranchesTempo_Person[0].StatutNumerique
              ? props.statutOptions.find(option => option.value === item.link_PersonTranchesTempo_Person[0].StatutNumerique)?.text
              : item.link_PersonTranchesTempo_Person && item.link_PersonTranchesTempo_Person.length > 0 && item.link_PersonTranchesTempo_Person[0].Statut
                ? props.statutOptions.find(option => option.correspondance === item.link_PersonTranchesTempo_Person[0].Statut)?.text
                : null;
          }
          const groupedPeriods = item.link_Wishlist_Person[0].Contenu.Dispo.reduce((acc, itm) => {
            const dateKey = itm.date;
            if (acc[dateKey]) acc[dateKey].push(itm.periode);
            else acc[dateKey] = [itm.periode];
            return acc;
          }, {});
          return (
            <>
              <Card key={index} className={(benSelected === item.ID) ? classes.highlightCardBenevole : classes.rootCardBenevole} >
                <CardActionArea id={item.ID} onClick={handleBenClick} className={classes.rootCardBenevole} onDoubleClick={handleBenDoubleClick}  /* onDoubleClick={alert("helo")} */>
                  <CardHeader
                    className={classes.changePadding}
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar} >
                        {/* <img alt={`Pix ${item.Firstname}`} src={`/4DACTION/IMAGE_PROFILE/?person==${item.ID}`} className={classes.imgAvatar} loading="lazy" /> */}
                        <LazyLoadImage src={`/4DACTION/IMAGE_PROFILE/?person==${item.ID}`}
                          // width={600} height={400}
                          className={classes.imgAvatar}
                          alt={`Pix ${item.Firstname}`}
                        />
                      </Avatar>
                    }
                    titleTypographyProps={{ variant: 'h4' }}
                    title={item.Firstname + " " + item.Name + " - " + statutPerson}
                  />
                  <CardContent className={classes.changePadding} key={GetRandomKey(12)}>
                    <>
                      <Grid container>
                        <Grid item xs={6} md={5}>
                          <span>Dates souhaitées</span>
                          <ul className={classes.removePadding}>
                            {/* {item.link_Wishlist_Person[0].Contenu.Dispo.map((itm, idx) => (
                              <li key={GetRandomKey(7)} className={classes.listClasse} ><b>{itm.date}</b> : {itm.periode}</li>
                            ))} */}
                            {Object.keys(groupedPeriods).map((date, idx) => (
                              <li key={idx} className={classes.listClasse}>
                                <b>{date}</b> : {groupedPeriods[date].join(', ')}
                              </li>
                            ))}
                          </ul>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <span>Activités souhaitées</span>
                          <ol className={classes.removePadding}>
                            {item.link_Wishlist_Person[0].Contenu?.ChoixActivitesNoFiltred?.map((itm, idx) => (
                              <li
                                key={GetRandomKey(12)}
                                style={{
                                  fontWeight: item.link_Wishlist_Person[0].Contenu.ChoixActivites[0].id === itm.id ? 'bold' : 'normal',
                                  textDecoration: (idx < itemIndex) ? 'line-through' : 'none',
                                  color: (idx < itemIndex) ? 'gray' : 'black'
                                }}
                              >
                                {itm.nom}
                              </li>
                            ))}
                          </ol>
                        </Grid>
                        <Grid item xs={6} md={3} style={{ display: 'flex', justifyContent: 'flex-start', }}>
                          <span>
                            {(item.Activites.length > 0) &&
                              <>
                                <span>Inscription</span>
                                <ul className={classes.removePadding}>
                                  {item.Activites.map((itm, idx) => (
                                    <li key={GetRandomKey(9)} className={classes.listClasse}>
                                      <>{itm.Nom} <br />
                                        {itm.TranchesDisctinct &&
                                          itm.TranchesDisctinct.map((trc, ix) => (
                                            <div key={GetRandomKey(7)}> {trc}, </div>
                                          ))
                                        }
                                      </>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            }
                          </span>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <span>Remarques sur les tranches horaires:</span>
                          <ul className={classes.paddingRemarque}>
                            {item.link_Wishlist_Person[0].Remarques.RemarquesTH}
                          </ul>
                          <br />
                          <span>Remarques générales</span>
                          <ul className={classes.paddingRemarque}>
                            {item.link_Wishlist_Person[0].Remarques.RemarqueGlobale}
                          </ul>
                        </Grid>
                      </Grid>
                      <br />
                    </>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Grid container>
                    <Grid item xs={2} md={1}>
                      <InfoIcon color="primary" className={classes.actionBtn} onClick={(e) => { handlePopoverOpen(e, item.ID) }} value={item.ID} />
                      <Popover
                        open={openedPopoverId === item.ID}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        classes={{
                          paper: classes.paper,
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        value={item.ID}
                      >
                        <Typography><i>Ses infos</i></Typography><br />
                        <Typography className={classes.typography}>{item.Birth}</Typography>
                        <Typography>{item.Phone_Mobile}</Typography><br />
                        <Typography>{item.Email}</Typography><br />
                        <Typography>{item.Address}<br />{item.Zip} {item.Locality} <br /> {item.Country}</Typography><br />
                        {(item.Commentaire !== "") &&
                          <><hr style={{ borderTop: '2px dotted grey ' }} /><Typography><i>Mon commentaire</i></Typography><br /> <Typography>{item.Commentaire}</Typography><br /> </>
                        }
                        <hr style={{ borderTop: '2px dotted grey ' }} /><br /> <Typography><i>Infos supplémentaires</i></Typography><br />
                        {manifInfoComple.map((itm, idx) => ( //item.InfosComplementaires.InfosComplementaires¨
                          <React.Fragment key={GetRandomKey(10)}><Typography>{itm.nomInfoComple} : {item.InfosComplementaires ? item.InfosComplementaires.InfosComplementaires[idx].valeur : ""}</Typography><br /></React.Fragment>
                        ))}
                      </Popover>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      {item.link_PersonTranchesTempo_Person.length > 0 ?
                        <>
                          {item.link_PersonTranchesTempo_Person[0].Statut !== "Valide" ?
                            <>
                              {item.link_PersonTranchesTempo_Person[0].Commentaire !== "" ?
                                <>
                                  <WarningIcon className={classes.actionBtn} color="secondary" onClick={(e) => { handlePopoverStatutOpen(e, item.ID) }} value={item.ID} />
                                  <Popover
                                    open={openedPopoverIdStatut === item.ID}
                                    anchorEl={anchorElStatut}
                                    onClose={handlePopoverStatutClose}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'left',
                                    }}
                                    value={item.ID}
                                  >
                                    <p>{item.link_PersonTranchesTempo_Person[0].Commentaire}</p>
                                  </Popover>
                                </>
                                :
                                <></>
                              }
                            </>
                            :
                            <CheckCircleIcon className={classes.actionBtnValide} style={{ color: 'green' }} />
                          }
                        </>
                        :
                        <></>
                      }
                    </Grid>
                    <Grid item xs={8} md={10} style={{ display: 'flex', justifyContent: 'flex-end', }}>
                      {((props.byResp && !props.afficherTraite && item.isResp) || (props.byResp && !item.activityID)) ?
                        <>
                          <Button variant="contained" className={classesGlobal.successBtn} startIcon={<CheckIcon />} style={{ marginRight: "10px" }} size="small" onClick={_ => item.activityID ? handleAcceptRefused(item.ID, item.link_Wishlist_Person[0].Contenu.Dispo, "accepted", item.activityID, item.link_Wishlist_Person[0].Contenu.ChoixActivites) : handleAffectation(item.ID, item.link_Wishlist_Person[0].Contenu.Dispo)}>
                            {item.activityID ? "Accepter" : "Affecter"}
                          </Button>
                          {item.activityID && <Button variant="contained" className={classesGlobal.buttonFermer} startIcon={<BlockIcon />} style={{ marginRight: "10px" }} size="small" onClick={_ => handleAcceptRefused(item.ID, item.link_Wishlist_Person[0].Contenu.Dispo, "refused", item.activityID)}>
                            Refuser
                          </Button>}
                        </> :
                        !props.byResp && <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.link_Wishlist_Person[0].Traite}
                              onChange={handleChange}
                              id={item.ID.toString()}
                              name="ckbPerson"
                              color="secondary"
                            />
                          }
                          label="Classé comme 'traité'"
                        />
                      }
                      {item.activityID &&
                        <Button onClick={() => handleAffectation(item.ID, item.link_Wishlist_Person[0].Contenu.Dispo)} variant="contained" color="primary">
                          Nouvelle attribution
                        </Button>
                      }
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
              <br />
            </>
          )
        })
        :
        <strong>Aucun bénévole ne correspond a cette recherche</strong>
      }
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    manif: state.manif,
  }
}


export default connect(mapStateToProps)(BenevolesCards)