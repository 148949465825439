import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import rest from '../../rest';
import * as moment from 'moment';
import { useStylesGlobal } from "../../pages/GlobalStyle.css"
import InfosBenevole from '../infosBenevole/InfosBenevole';
import { Dialog, DialogActions, Button, TextField, IconButton, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from "@material-ui/icons/Close";
import { useStyleLesBenevoles } from "../../pages/LesBenevoles/Lesbenevoles.css";
import AppContext from '../appContext';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

// Debut fonction 
// Lors de l'utilisation du readOnly --> précisé s'il est true/false
export default function DataTable({ filter, customToolbar, readOnly, valeurFiltre, attribut, nom }) {
  const [benevole, setBenevole] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Le CSS 
  const classesLesBenevoles = useStyleLesBenevoles();
  const classesGlobal = useStylesGlobal();
  // Le contenu du tableau 
  const [rowSelected, setRowSelected] = useState(-1);
  const [benevSelectedId, setBenevSelectedId] = useState(0);
  const [benevSelectedName, setBenevSelectedName] = useState("")
  const [benevSelectedFirstName, setBenevSelectedFirstName] = useState("")
  const [refreshBen, setRefreshBen] = useState(false)
  const myContext = useContext(AppContext);
  myContext.setRefreshBen = setRefreshBen
  myContext.benevole = benevole

  const [searchOpen, setSearchOpen] = useState(true);

  // Valeur Requete Benevole
  useEffect(() => {
    setLoading(true)
    let params = {
      newUi: true,
      vFiltre: valeurFiltre,
      vFiltreAttribut: attribut ? attribut.length > 0 ? ";" + attribut.join(";") : "" : "",
    };

    if (filter) {
      for (const key in filter) {
        if (typeof filter[key] === "object") {
          params[key] = JSON.stringify(filter[key])
        } else {
          params[key] = filter[key]
        }
      }
    }
    // Requete avec les parametres
    rest.post('/benevols/listebenevoles.json', params)
      .then(function (response) {
        setLoading(false)
        setBenevole(response.data)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
      });
  }, [filter, valeurFiltre, attribut, refreshBen])

  const columns = [
    {
      label: "ID",
      name: "ID",
      options: {
        download: false,
        viewColumns: false,
        filter: false,
        display: 'excluded',
      }
    },
    {
      label: "Nom",
      name: "Name",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Prénom",
      name: "Firstname",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Naissance",
      name: "Birth",
      options: {
        filter: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var dateFormatee = "";
          if (value !== "0000-00-00T00:00:00.000Z") {
            dateFormatee = moment(value).format('DD.MM.YYYY');
          }
          return dateFormatee
        },
      },
    },
    {
      label: "Mobile",
      name: "Phone_Mobile",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Email",
      name: "Email",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Dernière inscription",
      name: "lastInscription",
      options: {
        display: false,
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Dernière inscription",
      name: "lastInscriptionNumerique",
      options: {
        filter: false,
        sortThirdClickReset: true,
        display: valeurFiltre === "Edition",
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowData[6]
        },
      }
    },
    // {
    //   label: "Sexe",
    //   name: "Gender",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Adresse",
    //   name: "Address",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Adresse comp.",
    //   name: "Address_2",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "No Postal",
    //   name: "Zip",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Ville",
    //   name: "Locality",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Pays",
    //   name: "Country",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Tèl. privé",
    //   name: "Phone_Pers",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Tèl. pro",
    //   name: "Phone_Pro",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "T-Shirt",
    //   name: "Taille_Tshirt",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Groupe",
    //   name: "Groupe",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Points",
    //   name: "point",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Nbr Heure",
    //   name: "nbHeure",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
    // {
    //   label: "Première inscription",
    //   name: "firstSubscription",
    //   options: {
    //     download: true,
    //     viewColumns: false,
    //     filter: false,
    //     display: 'excluded',
    //   }
    // },
  ];

  useEffect(() => {
    if (benevSelectedId > 0) {
      setOpen(true)
    }
  }, [benevSelectedId]);



  const handleClose = () => {
    setOpen(false);
    setBenevSelectedId(0)
  };

  // Garder les donnes de la ligne selectionnée
  const handleLineSelection = (rowData, rowMeta) => {
    setRowSelected(rowMeta.dataIndex)
    let ben = benevole[rowMeta.dataIndex]
    setBenevSelectedId(ben.ID)
    setBenevSelectedName(ben.Name)
    setBenevSelectedFirstName(ben.Firstname)
  };

  const options = {
    search: !searchOpen,
    searchOpen: searchOpen,
    onSearchOpen: () => setSearchOpen(true),
    onSearchClose: () => setSearchOpen(false),
    searchPlaceholder: "Rechercher...",
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div style={{ marginRight: "16px" }}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={options.searchPlaceholder}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => hideSearch()} size="small">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      );
    },

    filter: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: { name: "Name", direction: 'asc' },
    downloadOptions: { filename: "donneeBenevole.csv", separator: ';' },
    onDownload: function (buildHead, buildBody, columns, data) {
      if ((typeof valeurFiltre !== 'undefined') && (typeof attribut !== 'undefined')) {
        window.open("/export/benevoles/globale?vFiltre=" + valeurFiltre + "&vFiltreAttribut=" + attribut.join(";"));
      } else {
        window.open("/export/benevoles/globale?vFiltre=mesBenevoles");
      }
      return false
    },
    onRowClick: handleLineSelection,
    customToolbar: customToolbar ? customToolbar : null,
    textLabels: {
      "body": {
        "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
        "toolTip": "Trier"
      },
      "pagination": {
        "next": "Page suivante",
        "previous": "Page précédente",
        "rowsPerPage": "Ligne par page:",
        "displayRows": "de",
        "jumpToPage": "Aller à la page:"
      },
      "toolbar": {
        "search": "Rechercher",
        "downloadCsv": "Télécharger CSV",
        "viewColumns": "Afficher les colonnes",
        "filterTable": "Tableau des filtres"
      },
      "viewColumns": {
        "title": "Afficher les colonnes",
        "titleAria": "Afficher/Masquer les colonnes du tableau"
      },
      "selectedRows": {
        "text": "ligne(s) sélectionnée(s)",
        "delete": "Effacer",
        "deleteAria": "Supprimer les lignes sélectionnées"
      }
    },
    setRowProps: (row, rowIndex) => {
      return {
        className: clsx({
          [classesLesBenevoles.SelectedRow]: rowIndex === rowSelected,
        }),
      };
    },
  };



  //////////////////////////////////
  // Return
  /////////////////////////////////
  return (
    <>
      <LoadingIndicator open={loading} />
      <MUIDataTable
        title={<h1>{nom}</h1>}
        data={benevole}
        columns={columns}
        options={options}
      />
      {benevSelectedId > 0 &&
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          // disableBackdropClick
          scroll="body"
          fullScreen
        // style={{zIndex:15000}}
        >
          <InfosBenevole readOnly={readOnly} idBenevole={benevSelectedId} benevSelectedName={benevSelectedName} benevSelectedFirstName={benevSelectedFirstName} handleClose={handleClose} />
          <DialogActions>
            <Button variant="contained" onClick={handleClose} className={classesGlobal.buttonFermer}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>}
    </>
  );
};