import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Avatar, Box, Card, CardActions, CardContent, Grid, Typography, Fab, Badge } from '@material-ui/core';
import BenevoleTabs from '../onglets/BenevoleTabs';
import rest from "../../rest";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Wallet from '@material-ui/icons/AccountBalance';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import FormulaireInscription from '../formulaire/FormulaireInscription';
import { MySnackbar } from '../mySnackbar/MySnackbar'
import PhotoProfil from '../PhotoProfil/PhotoProfil';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useSelector } from "react-redux";
import { CustomTheme1 } from "../css/CustomTheme1.css";
import { CustomTheme2 } from "../css/CustomTheme2.css";
import { CustomTheme3 } from "../css/CustomTheme3.css";
import { CustomTheme4 } from "../css/CustomTheme4.css";
import { CustomTheme5 } from "../css/CustomTheme5.css";
import { useStyles } from "./InfosBenevole.css";

const HeadAvatar = withStyles({
    img: {
        objectFit: "fill"
    }
})(Avatar);

const InfosBenevole = ({ readOnly, idBenevole, benevSelectedFirstName, benevSelectedName, handleClose }) => {
    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);
    const [avatarPix, setAvatarPix] = useState(`/4DACTION/IMAGE_PROFILE/?person=${idBenevole}`)
    const [snack, setSnack] = useState({});
    const [open, setOpen] = useState(false);
    const [ticketView, setTicketView] = useState(false);
    const [ticketID, setTicketId] = useState('');
    const [ticketUrl, setTicketUrl] = useState('');
    const manifParams = useSelector(state => state.manif);

    let classesSelected = null
    const theme1 = CustomTheme1();
    const theme2 = CustomTheme2();
    const theme3 = CustomTheme3();
    const theme4 = CustomTheme4();
    const theme5 = CustomTheme5();
    switch (manifParams.manifParams.Theme) {
        case "theme1":
            classesSelected = theme1
            break;
        case "theme2":
            classesSelected = theme2
            break;
        case "theme3":
            classesSelected = theme3
            break;
        case "theme4":
            classesSelected = theme4
            break;
        case "theme5":
            classesSelected = theme5
            break;
        default:
            break;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleEditBen = () => {
        setShowForm(true)
    }

    const handlePrintBenevoleFiche = () => {
        window.open("/export/benevoles/tranche/pdf/?id=" + idBenevole)
    }

    const handlePrintAccredFiche = () => {
        window.open("/export/benevoles/accreditation/pdf/?id=" + idBenevole)
    }

    useEffect(() => {
        let test = `/4DACTION/IMAGE_PROFILE/?person=${idBenevole}&alea=` + Math.floor(Math.random() * 1000000)
        setAvatarPix(test)
    }, [open, idBenevole])

    const getTiketId = () => {
        rest.post(`/benevols/getTiketId.json`, { vIdPers: idBenevole }).then((response) => {
            if (response.data.error !== true) {
                setTicketId(response.data.ticketUUID)
                setTicketUrl(response.data.url)
                setTicketView(true);
            }
        });
    }
    getTiketId();

    const openTicketAcount = () => {
        let randString = strRandom({ includeUpperCase: true, includeNumbers: true, length: 5, startsWithLowerCase: true });
        window.open('https://' + ticketUrl + "/ticket/view/uuid/" + ticketID + '?' + randString, '_blank', 'noopener,noreferrer');
    }

    function strRandom(o) {
        var a = 10,
            b = 'abcdefghijklmnopqrstuvwxyz',
            c = '',
            d = 0,
            e = '' + b;
        if (o) {
            if (o.startsWithLowerCase) {
                c = b[Math.floor(Math.random() * b.length)];
                d = 1;
            }
            if (o.length) {
                a = o.length;
            }
            if (o.includeUpperCase) {
                e += b.toUpperCase();
            }
            if (o.includeNumbers) {
                e += '1234567890';
            }
        }
        for (; d < a; d++) {
            c += e[Math.floor(Math.random() * e.length)];
        }
        return c;
    }

    return (
        idBenevole > 0 &&
        <>
            <PhotoProfil open={open} setOpen={setOpen} idBenevole={idBenevole} />
            <MySnackbar params={snack} />
            <Card>
                <CardContent className={classesSelected.cardHeader} >
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <Box textAlign="right">
                                {showForm ?
                                    <Badge color="primary" onClick={handleClickOpen} badgeContent={<PhotoCamera />} overlap="rectangular" className={classes.badge}>
                                        <HeadAvatar alt="Profil photo" src={avatarPix} className={classes.profilPix} />
                                    </Badge>
                                    :
                                    <HeadAvatar alt="Profil photo" src={avatarPix} className={clsx(classes.profilPix, classes.badge)} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={7} className={classes.middleGrid}>
                            <Typography gutterBottom variant="h3" component="h2">
                                {benevSelectedName} {benevSelectedFirstName}
                            </Typography>
                            <Typography variant="caption" display="block">{idBenevole}  </Typography>
                        </Grid>
                        {!showForm && <Grid item xs={3} className={classes.grid}>
                            {!readOnly && <Fab color="primary" onClick={handleEditBen} aria-label="Editer fiche bénévole" title="Editer fiche bénévole">
                                <EditIcon fontSize="medium" />
                            </Fab>}
                            {manifParams.manifInfos.Ref === "MeyRun" && <Fab className={classes.fab} color="primary" onClick={handlePrintAccredFiche}
                                aria-label="Visualiser fiche accréditations bénévole" title="Visualiser fiche accréditations bénévole">
                                <CardMembershipIcon fontSize="medium" />
                            </Fab>}
                            <Fab className={classes.fab} color="primary" onClick={handlePrintBenevoleFiche}
                                aria-label="Visualiser fiche bénévole" title="Visualiser fiche bénévole">
                                <PermContactCalendarIcon fontSize="medium" />
                            </Fab>
                            {ticketView && <Fab className={classes.fab} color="primary" onClick={openTicketAcount}
                                aria-label="Visualiser le solde bénévole" title="Visualiser le solde bénévole">
                                <Wallet fontSize="medium" />
                            </Fab>}
                            <Fab className={clsx(classes.buttonFermer, classes.fab)} onClick={handleClose} aria-label="Fermer" title="Fermer">
                                <CloseIcon fontSize="medium" />
                            </Fab>
                        </Grid>}
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardAction} >
                    {showForm ?
                        <FormulaireInscription idBenevole={idBenevole} setShowForm={setShowForm} setSnack={setSnack} />
                        :
                        <BenevoleTabs readOnly={readOnly} idBenevole={idBenevole} benevSelectedName={benevSelectedName} benevSelectedFirstName={benevSelectedFirstName} handleCloseFiche={handleClose} />
                    }
                </CardActions>
            </Card>
        </>
    )
}

export default InfosBenevole;


