import React, { useContext, useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppContext from '../appContext';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyleMenu } from './menu.css';
import { Avatar, Button, Drawer, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, Card, CardContent, FormControl, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import MailIcon from '@material-ui/icons/Mail';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import StorageIcon from '@material-ui/icons/Storage';
import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getManifParams } from '../../actions/manif';
import rest from '../../rest'
import { useHistory, useLocation } from "react-router-dom";
import { CustomTheme1 } from "../css/CustomTheme1.css";
import { CustomTheme2 } from "../css/CustomTheme2.css";
import { CustomTheme3 } from "../css/CustomTheme3.css";
import { CustomTheme4 } from "../css/CustomTheme4.css";
import { CustomTheme5 } from "../css/CustomTheme5.css";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ForgotPassword from '../../components/forgotPassword/ForgotPassword';
import { useCookies } from 'react-cookie';

const icons = {
    AppsOutlinedIcon: <AppsOutlinedIcon fontSize="small" />,
    ListOutlinedIcon: <ListOutlinedIcon fontSize="small" />,
    FavoriteIcon: <FavoriteIcon fontSize="small" />,
    PeopleAltOutlinedIcon: <PeopleAltOutlinedIcon fontSize="small" />,
    MailIcon: <MailIcon fontSize="small" />,
    GroupIcon: <GroupIcon fontSize="small" />,
    PersonIcon: <PersonIcon fontSize="small" />,
    DirectionsRunIcon: <DirectionsRunIcon fontSize="small" />,
    StorageIcon: <StorageIcon fontSize="small" />,
    TimelineIcon: <TimelineIcon fontSize="small" />,
    SwapHorizIcon: <SwapHorizIcon fontSize="small" />,
    RotateLeftIcon: <RotateLeftIcon fontSize="small" />,
    CalendarTodayIcon: <CalendarTodayIcon fontSize="small" />,
    SettingsIcon: <SettingsIcon fontSize="small" />,
}

const MenuAvatar = withStyles({
    img: {
        objectFit:"fill"
    }
})(Avatar);

const SidebarMenu = (props) => {
    const classesMenu = useStyleMenu();
    const myContext = useContext(AppContext);
    const location = useLocation()
    const openSidebar = myContext.openSidebar;
    const [snack, setSnack] = useState({});
    const [loading, setLoading] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);
    const userConnected = props.user.User;
    // const personConnected = props.user.Person;
    myContext.User_ID =userConnected.User_Person_ID;
    myContext.User_Administrator =userConnected.User_Administrator;
    myContext.User_Chef = userConnected.User_Chef
    const [avatarPix, setAvatarPix] = useState(`/4DACTION/IMAGE_PROFILE/?profile=${userConnected.User_ID}`);
    const [menuList, setMenuList] = useState([])
    const history = useHistory();
    const [reload, setReload] = useState(false);
    const [cookiesLogin, setCookiesLogin] = useCookies(['SessionLogin'])
    const [cookiesSaveLogin, setCookiesSaveLogin] = useCookies(['SaveLogin'])
    myContext.setReload = setReload
    //connexion
    const [remember, setRemember] = useState(cookiesSaveLogin.SaveLogin ? (cookiesSaveLogin.SaveLogin==="true"):false);
    const [connexionEmail, setConnexionEmail] = useState(cookiesLogin.SessionLogin ? cookiesLogin.SessionLogin : "");
    const [connexionEmailError, setConnexionEmailError] = useState(false);
    const [connexionPassword, setConnexionPassword] = useState("");
    const [connexionPasswordError, setConnexionPasswordError] = useState(false);
    const [connexionEmailErrorMsg, setConnexionEmailErrorMsg] = useState("");

    let classesSelected = null
    const theme1 = CustomTheme1();
    const theme2 = CustomTheme2();
    const theme3 = CustomTheme3();
    const theme4 = CustomTheme4();
    const theme5 = CustomTheme5();

    switch (props.manif.manifParams.Theme) {
        case "theme1":
            classesSelected = theme1
            break;
        case "theme2":
            classesSelected = theme2
            break;
        case "theme3":
            classesSelected = theme3
            break;
        case "theme4":
            classesSelected = theme4
            break;
        case "theme5":
            classesSelected = theme5
            break;
        default:
            break;
    }

    useEffect(() => {

        rest.get('/benevols/getMenu.json').then((response) => {
            setMenuList(response.data)
        }, (error) => {
            console.log(error);
        });

    }, []);

    useEffect(() => { 
        setAvatarPix(`/4DACTION/IMAGE_PROFILE/?profile=${userConnected.User_ID}&alea=`+Math.floor(Math.random() * 1000000))
    }, [reload, userConnected.User_ID])

    const handleNavigate = (link) => {
        history.push(link)
    }

    const handleLogout = () => {
        rest.post('/user/logout.json',{}).then((response) => {
            window.location.href = "/connexion.html"
        }, (error) => {
            console.log(error);
        });
    }


    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(email) && email !== "");
    }
    const handleConnect = () => {
        let error = false;

        if (connexionEmail === "") {
            error = true
            setConnexionEmailError(true)
            setConnexionEmailErrorMsg("Champ obligatoire")
        } else {
            if (!validateEmail(connexionEmail)) {
                setConnexionEmailError(true)
                setConnexionEmailErrorMsg("Format email invalide")
            } else {
                setConnexionEmailError(false)
                setConnexionEmailErrorMsg("")
            }
        }
        if (connexionPassword === "") {
            error = true
            setConnexionPasswordError(true)
        } else {
            setConnexionPasswordError(false)
        }

        if (!error) {
            setLoading(true)
            var paramsData = new FormData();
            paramsData.append("vConnexionEmail", connexionEmail);
            paramsData.append("vConnexionPassword", connexionPassword);
            rest.post('/user/connexion.json', paramsData).then(function (response) {
                if (response.data.error) {
                    setSnack({ message: response.data.Message, show: true, severity: "error" })
                } else {
                    setCookiesSaveLogin('SaveLogin', remember, { path: '/' });
                    if (remember) {
                        setCookiesLogin('SessionLogin', connexionEmail, { path: '/' });
                    } else {
                        setCookiesLogin('SessionLogin', "", { path: '/' });
                    }
                    window.location.href = "/index.html"
                }
                setLoading(false)
            })
        }

    }

    const handleForgetPassword = () => {
        setOpenForgotPassword(true)
    }

    const submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            handleConnect();
        }
    }
    const handleRemember = () => {
        setRemember((prev) => !prev)
    }


    return (
        <Drawer
            className={classesMenu.drawer}
            variant="persistent"
            anchor="left"
            open={openSidebar}
            classes={{
                paper: classesSelected.leftmenu
            }}
        >
            {(userConnected.User_Logged) ?
                <>
                    <div className={classesMenu.centering} >
                        <MenuAvatar alt="Profil photo" src={avatarPix} className={classesSelected.avatarProfil} />
                    </div>
                    <div className={classesMenu.centering} >
                        <p className={classesSelected.bonjourProfil}>Bonjour <b>{userConnected.User_Name}</b></p>
                    </div>
                    <div className={classesMenu.centering} >
                        <Tooltip
                            title={<h3>Mon profil</h3>}
                            aria-label="Mon profil"
                            placement="top"
                        >
                            <Link href="/profile_informations.html" ><Avatar alt="Profil" className={classesSelected.iconsProfil} ><PersonOutlineIcon /></Avatar></Link>
                        </Tooltip>
                        <Tooltip
                            title={<h3>Déconnexion</h3>}
                            aria-label="Déconnexion"
                            placement="top"
                        >
                            <Link onClick={handleLogout}><Avatar alt="Deconnexion" className={classesSelected.iconsProfil} ><PowerSettingsNewIcon /></Avatar></Link>
                        </Tooltip>
                    </div>
                    <List style={{'marginBottom':'40px'}}>
                        {menuList.map((item, index) => {
                            return !item.isReact ?
                                <a key={index} href={item.link} className={classesMenu.menuLink}>
                                    <ListItem className={classesSelected.listMenu} button key={index}>
                                        <ListItemIcon className={classesSelected.iconsMenu}>{icons[item.icon]}</ListItemIcon>
                                        <ListItemText className={classesSelected.textMenu} primary={<Typography variant="subtitle1" className={classesSelected.textMenu}>{item.name}</Typography>} />
                                    </ListItem>
                                </a>
                                :
                                <ListItem onClick={_ => handleNavigate(item.link)} className={classesSelected.listMenu} button key={index} selected={location.pathname === item.link} >
                                    <ListItemIcon className={classesSelected.iconsMenu}>{icons[item.icon]}</ListItemIcon>
                                    <ListItemText className={classesSelected.textMenu} primary={<Typography variant="subtitle1" className={classesSelected.textMenu}>{item.name}</Typography>} />
                                </ListItem>
                        })}
                    </List>
                </>
                :
                <div className={classesSelected.connexionPanel}>
                    <MySnackbar params={snack} />
                    <LoadingIndicator open={loading} />
                    <ForgotPassword openDialog={openForgotPassword} setOpenDialog={setOpenForgotPassword} defaultEmail={connexionEmail}/>
                    {props.manif.manifInfos.StatutInscription>1 && <><Typography variant="h6" component="div" className={classesSelected.connexionCreate}><b>Pas encore de compte?</b></Typography>
                    <br />
                        <Button variant="contained" size="small" color="secondary" onClick={_ => history.push("/connexion.html")}> Créer un compte bénévole </Button></>}
                    <br /><br /><br />
                    <Typography variant="h4" className={classesSelected.connexionTitle}><b>Connectez-vous</b></Typography>
                    <br />
                    <Card >
                        <CardContent style={{ paddingLeft: "5px", paddingRight: "5px"}}>
                    <FormControl>
                                <TextField
                                size="small"
                                id="vConnexionEmailSideBar"
                                name="vConnexionEmail"
                                label="Votre adresse email"
                                required
                                type={'email'}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmailIcon style={{ fontSize: "20px" }}/>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: classesSelected.sidebarField,
                                    },
                                }}
                                fullWidth
                                value={connexionEmail}
                                onChange={(newValue) => {
                                    setConnexionEmail(newValue.target.value)
                                }}
                                error={connexionEmailError}
                                helperText={connexionEmailError && connexionEmailErrorMsg}
                                onKeyDown={(e) => submitOnEnter(e)}
                        />
                    </FormControl>
                    <br /><br />
                    <FormControl>
                                <TextField
                                    className={classesSelected.sidebarField}
                                    size="small"
                                id="vConnexionPasswordSideBar"
                                name="vConnexionPassword"
                                label="Votre mot de passe"
                                required
                                type={'password'}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={{ fontSize: "20px" }}/>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        input: classesSelected.sidebarField,
                                    },
                                }}
                                fullWidth
                                value={connexionPassword}
                                onChange={(newValue) => {
                                    setConnexionPassword(newValue.target.value)
                                }}
                                error={connexionPasswordError}
                                helperText={connexionPasswordError && "Champ obligatoire"}
                                onKeyDown={(e) => submitOnEnter(e)}
                        />
                            </FormControl>
                            <Link className={classesMenu.linkStyle} onClick={handleForgetPassword}>J'ai oublié mon mot de passe</Link>
                        </CardContent>
                    </Card>
                    <br />
                    
                    <br />
                    <Button variant="contained" size="medium" fullWidth color="primary" onClick={handleConnect}> Connexion </Button>
                    <br />
                    <FormControlLabel
                        control={<Checkbox checked={remember} onChange={handleRemember} color="primary" name="rememberMe" className={classesMenu.rememberCheck} />}
                        label="Se souvenir de moi"
                    />
                </div>
            }
        </Drawer>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getManifParams }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
