import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    profilPix: {
        width: 80,
        height: 80,
    },
    middleGrid: {
        marginLeft:30,
    }, 
    grid :{
        textAlign:"right"
    },
    fab :{
        marginLeft:"10px"
    },
    cardAction : {
        maxWidth: '100%', 
        padding:"0vw" 
    },
    badge : { 
        marginInline: "3vw" 
    },
    buttonFermer: {
        backgroundColor: "#d32f2f",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#ab2626",
        },
    },
}));

