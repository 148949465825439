import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import DatesAutocomplete from '../../components/autoComplete/DatesAutocomplete';
import EtOuSelect from '../../components/autoComplete/EtOuSelect';
import ActivitesAutocomplete from '../../components/autoComplete/ActivitesAutocomplete';
import ActivitesDefAutocomplete from '../../components/autoComplete/ActivitesDefAutocomplete';
import StatutAutocomplete from '../../components/autoComplete/StatutAutocomplete';
import CardBenevoles from '../../components/cards/BenevolesCards';
import CardActivites from '../../components/cards/ActivitesCards';
import { CircularProgress, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import rest from '../../rest';
import {useStylesGlobal} from "../GlobalStyle.css";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import statutOptions from '../../components/general/statutBenevoleTemp';


const Attributions = (props) => {
    const classesGlobal = useStylesGlobal();
    const [userAdmin, setUserAdmin] = useState(false);
    const [userChef, setUserChef] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [actsDefDispo, setActsDefDispo] = useState([])
    const [benAPlacer, setBenAPlacer] = useState([]);
    const [filtreDates, setFiltreDates] = useState([])
    const [filtreListeAttente, setFiltreListeAttente] = useState(false)
    const [filtreActivites, setFiltreActivites] = useState([])
    const [filtreActivitesDef, setFiltreActivitesDef] = useState([])
    const [benSelected, setBenSelected] = useState(0)
    const [thSelected, setTHSelected] = useState(0)
    const [benToRemove, setBenToRemove] = useState(0)
    const [thToRemove, setThToRemove] = useState(0)
    const [thToRemoveStatut, setThToRemoveStatut] = useState("")
    const [afficherTraite, setAfficherTraite] = useState(false)
    const [triSurNbDispo, setTriSurNbDispo] = useState(false)
    const [afficherFullAct, setAfficherFullAct] = useState(false)
    const [rendreBenWishlistTraitee, setRendreBenWishlistTraitee] = useState(false)
    const [benWishlistTraiteeToUpdate, setBenWishlistTraiteeToUpdate] = useState(0)
    const [benLoaded, setBenLoaded] = useState(false)
    const [statut, setStatut] = useState(-4)
    const [etOuSelect, setEtOuSelect] = useState([{ valeur: 'et', texte: 'Et' }])
    const [listeDatesFiltre, setListeDateFiltre] = useState([])
    const [listeActFiltre, setListeActFiltre] = useState([])
    const [listeActDefinitiveFiltre, setListeActDefinitiveFiltre] = useState([])
    const [refreshBenList, setRefreshBenList] = useState(false)
    const manifParams = useSelector(state => state.manif);
    const [selectedOrderChoice, setSelectedOrderChoice] = useState("")
    const [orderChoiceList, setOrderChoiceList] = useState([])
    const currentUser = useSelector(state => state.user);

    useEffect(() => {
        setUserAdmin(currentUser.User.User_Administrator)
        setUserChef(currentUser.User.User_Chef)

        rest.get("/activites/recupDatesDispoWishlistSansAlias.json",
            // data: encodeURIComponent(JSON.stringify(paramsData)),
        )
            .then((response) => {
                setListeDateFiltre(response.data)
            })

        rest.get("/activites/activitesDisponiblesAll.json")
            .then((response) => {
                setListeActFiltre(response.data)
            })

        rest.get("/activites/activitesDefinitiveDispoAll.json",)
            .then((response) => {
                setListeActDefinitiveFiltre(response.data)
            })

        rest.get("/activites/getAllOrderChoiceList.json")
            .then((response) => {
                setOrderChoiceList(response.data)
            })
        

    }, [currentUser])

    useEffect(() => {
        if (filtreActivites.length===0) {
            setSelectedOrderChoice("")
        }
    }, [filtreActivites])

    useEffect(() => {
        getAllBenWishlist();
        // eslint-disable-next-line
    }, [filtreDates, filtreActivites, afficherTraite, triSurNbDispo, statut, refreshBenList, filtreListeAttente, etOuSelect, selectedOrderChoice])

    useEffect(() => {
        getActsDef();
        // eslint-disable-next-line
    }, [filtreDates, filtreActivitesDef, afficherFullAct, etOuSelect])

    useEffect(() => {
        if ((benSelected !== 0) && (thSelected !== 0) && (thSelected !== "")) {
            addBenTH();
        }
        // eslint-disable-next-line
    }, [benSelected, thSelected])

    useEffect(() => {
        removeBenTH();
        // eslint-disable-next-line
    }, [benToRemove, thToRemove, thToRemoveStatut])

    useEffect(() => {
        if (benWishlistTraiteeToUpdate !== 0) {
            updateWishlistTraite()
        }
        // eslint-disable-next-line
    }, [rendreBenWishlistTraitee, benWishlistTraiteeToUpdate])

    const getAllBenWishlist = () => {
        var paramsData = {
            'vBenTraite': afficherTraite,
            'vDates': JSON.stringify(filtreDates),
            'vEtOu': JSON.stringify(etOuSelect),
            'vActs': JSON.stringify(filtreActivites),
            'vOrderChoice' : selectedOrderChoice,
            'vStatut': statut,
            'vTriNbDispo': triSurNbDispo,
            'vListeAttente':filtreListeAttente,
        }
        setIsLoaded(false);
        rest.post('/benevols/listeBenevolesAPlacerNew.json', encodeURIComponent(JSON.stringify(paramsData)))
            .then((response) => {
                setBenAPlacer(response.data)
                setIsLoaded(true)
                setBenLoaded(true)
            })
    }

    const getActsDef = () => {
        var paramsData = {
            'vDates': JSON.stringify(filtreDates),
            'vActs': JSON.stringify(filtreActivitesDef),
            'vActsFull': afficherFullAct
        }
        rest.post('/activites/activitesDefinitiveDispoAll.json', encodeURIComponent(JSON.stringify(paramsData)))
            .then((response) => {
                setActsDefDispo(response.data)
                setIsLoaded(true)
            })
    }

    const addBenTH = () => {
        if (benSelected !== 0 && thSelected !== 0) {

            var paramsData = {
                'vBen': benSelected,
                'vTH': thSelected,
            }
            rest.post('/benevols/inscriptionTempo.json', encodeURIComponent(JSON.stringify(paramsData)))
                .then((response) => {
                    if (response.data.Message === "OK") {
                        if (statut === 2) {
                            setStatut(-5)
                            setTimeout(() => {
                                setTHSelected(0)
                                getActsDef();
                                //getAllBenWishlist(); 
                            }, 1000);
                        } else {
                            setTHSelected(0)
                            getActsDef();
                            getAllBenWishlist();
                        }
                    } else if (response.data.Message === "AlreadyIn") {
                        alert("Ce bénévole est déjà inscrits pour cette tranche horaire")
                        setTHSelected(0)
                    } else if (response.data.Message === "Conflit") {
                        alert("Ce bénévole ne peut pas être inscrit à cette activité car il est déja prit pour cette date/heure")
                        setTHSelected(0)
                    } else {
                        alert("Une erreur s'est produite. Veuillez contacter l'administrateur")
                    }
                })
        }
    }

    const removeBenTH = () => {
        if (benToRemove !== 0 && thToRemove !== 0) {
            var paramsData = {
                'vBen': benToRemove,
                'vTH': thToRemove,
                'vTHstatut': thToRemoveStatut
            }
            rest.post('/benevols/inscriptionTempoDelete.json', encodeURIComponent(JSON.stringify(paramsData)))
                .then((response) => {
                    if (response.data.Message === "OK") {
                        if (statut === 2) {
                            setStatut(-5)
                            setTimeout(() => {
                                setBenToRemove(0);
                                setThToRemove(0);
                                getActsDef();
                                //getAllBenWishlist(); 
                            }, 1000);
                        } else {
                            setBenToRemove(0);
                            setThToRemove(0);
                            getActsDef();
                            getAllBenWishlist();
                        }

                    } else {
                        alert("Une erreur s'est produite. Veuillez contacter l'administrateur")
                    }
                })
        }
    }

    const updateWishlistTraite = () => {
        var paramsData = {
            'vBen': benWishlistTraiteeToUpdate,
            'vTraite': rendreBenWishlistTraitee,
        }
        rest.post('/benevols/updateTraiteWishlist.json', encodeURIComponent(JSON.stringify(paramsData)))
            .then((response) => {
                if (response.data.Message === "OK") {
                    getAllBenWishlist();
                    setBenWishlistTraiteeToUpdate(0)
                } else {
                    alert("Une erreur s'est produite. Veuillez contacter l'administrateur")
                }
            })
    }

    const handleChange = (event) => {
        setAfficherTraite(event.target.checked);
    };

    const handleChangeListAttente = (event) => {
        setFiltreListeAttente(event.target.checked);
    };

    const handleChangeFullAct = (event) => {
        setAfficherFullAct(event.target.checked);
    }
    const handleChangeTriNbDispo = (event) => {
        setTriSurNbDispo(event.target.checked);
    }
    const handleChangeSelectedOrderChoice = (event) => {
        setSelectedOrderChoice(event.target.value);
    }

    return (
        <>
            <CssBaseline />
            {isLoaded ?
                <>
                    <Grid container spacing={1} className={classesGlobal.fixerPosition} >
                       { !manifParams.manifParams.InscriptionWishlistAttributionParResp && <><Grid item xs={6} md={10} >
                            <DatesAutocomplete datesDispoWishlist={listeDatesFiltre} filtreDates={filtreDates} setFiltreDates={setFiltreDates} />
                        </Grid>
                        <Grid item xs={6} md={2} >
                                <EtOuSelect etOuSelect={etOuSelect} setEtOuSelect={setEtOuSelect}/>
                        </Grid>
                        </>
                        }
                        <br /><br />
                        <Grid container>
                        <Grid item xs={12} md={manifParams.manifParams.InscriptionWishlistAttributionParResp? 12 : 6}>
                                {(userAdmin || userChef) ?
                                    <>
                                        {!manifParams.manifParams.InscriptionWishlistAttributionParResp && <>
                                            <br /><br /><ActivitesAutocomplete allActsWishlist={listeActFiltre} filtreActivites={filtreActivites} setFiltreActivites={setFiltreActivites} />
                                            {filtreActivites.length > 0 && <FormControl style={{ width: "25%", marginTop: "10px", marginLeft:"5px"}}>
                                                <InputLabel id="orderChoiceLabel">Ordre choix</InputLabel>
                                                <Select
                                                    labelId="orderChoiceLabel"
                                                    id="orderChoice"
                                                    value={selectedOrderChoice}
                                                    onChange={handleChangeSelectedOrderChoice}
                                                >
                                                    <MenuItem value={0}></MenuItem>
                                                    {orderChoiceList.map((value, index) => (
                                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>}
                                        </>}<br />
                                        <Grid container>
                                            <Grid item xs={6} md={6}>
                                                {!manifParams.manifParams.InscriptionWishlistAttributionParResp ? <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={afficherTraite}
                                                            onChange={handleChange}
                                                            name="ckbShowTraite"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Afficher les bénévoles traités"
                                                />
                                                :
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={filtreListeAttente}
                                                                onChange={handleChangeListAttente}
                                                                name="ckbShowAttente"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Bénévoles en liste d'attente"
                                                    /> }
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={triSurNbDispo}
                                                            onChange={handleChangeTriNbDispo}
                                                            name="ckbOrderByDispo"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Trier par nombre de dispo "
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <StatutAutocomplete statut={statut} setStatut={setStatut} statutOptions={statutOptions} currentUser={currentUser} /><br />
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <></>
                                }
                                {benLoaded ?
                                    <div>
                                        <CardBenevoles
                                        setRefreshBenList={setRefreshBenList}
                                        benAPlacer={benAPlacer}
                                        benSelected={benSelected}
                                        setBenSelected={setBenSelected}
                                        rendreBenWishlistTraitee={rendreBenWishlistTraitee}
                                        setRendreBenWishlistTraitee={setRendreBenWishlistTraitee}
                                        benWishlistTraiteeToUpdate={benWishlistTraiteeToUpdate}
                                        setBenWishlistTraiteeToUpdate={setBenWishlistTraiteeToUpdate}
                                        byResp={manifParams.manifParams.InscriptionWishlistAttributionParResp}
                                        afficherTraite={afficherTraite}
                                        statutOptions={statutOptions} />
                                    </div>
                                    :
                                    <><br /><br /><CircularProgress /></>
                                }

                            </Grid>
                        {!manifParams.manifParams.InscriptionWishlistAttributionParResp && <><Grid item md={1} ></Grid>
                                <Grid item xs={12} md={5} className={classesGlobal.scrollMax} >
                                {userAdmin ?
                                    <>
                                            <br /><br /><ActivitesDefAutocomplete allActs={listeActDefinitiveFiltre} filtreActivitesDef={filtreActivitesDef} setFiltreActivitesDef={setFiltreActivitesDef} />
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={afficherFullAct}
                                                            onChange={handleChangeFullAct}
                                                            name="ckbShowFullAct"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Afficher les activités complètes"
                                                />
                                            </Grid>
                                        {/* <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={afficherTraite}
                                                        onChange={handleChange}
                                                        name="ckbShowTraite"
                                                        color="primary"
                                                    />
                                                }
                                                label="Afficher les bénévoles traités"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={triSurNbDispo}
                                                        onChange={handleChangeTriNbDispo}
                                                        name="ckbOrderByDispo"
                                                        color="primary"
                                                    />
                                                }
                                                label="Trier par nombre de dispo "
                                            />
                                        </Grid> */}
                                    </>
                                    :
                                    <></>
                                }
                                <><br /><br /><br />  <CardActivites actsDefDispo={actsDefDispo} thSelected={thSelected} setTHSelected={setTHSelected} setBenToRemove={setBenToRemove} setThToRemove={setThToRemove} setThToRemoveStatut={setThToRemoveStatut} /></>
                            </Grid></>}
                        </Grid>
                        {/* <Grid item md={1} ></Grid>
                        <Grid item xs={12} md={5} className={classesGlobal.scrollMax} >
                            {(userAdmin && !manifParams.manifParams.InscriptionWishlistAttributionParResp) ?
                                <>
                                    <ActivitesDefAutocomplete allActs={listeActDefinitiveFiltre} filtreActivitesDef={filtreActivitesDef} setFiltreActivitesDef={setFiltreActivitesDef} />
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={afficherFullAct}
                                                    onChange={handleChangeFullAct}
                                                    name="ckbShowFullAct"
                                                    color="primary"
                                                />
                                            }
                                            label="Afficher les activités complètes"
                                        />
                                    </Grid>
                                </>
                                :
                                <></>
                            }
                            <><br /><br /><br />  {!manifParams.manifParams.InscriptionWishlistAttributionParResp && <CardActivites actsDefDispo={actsDefDispo} thSelected={thSelected} setTHSelected={setTHSelected} setBenToRemove={setBenToRemove} setThToRemove={setThToRemove} setThToRemoveStatut={setThToRemoveStatut} />}</>
                        </Grid> */}
                    </Grid>
                </>
                :
                <CircularProgress />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        activite: state.activite,
        benevoles: state.benevoles
    }
}


export default connect(mapStateToProps)(Attributions)
