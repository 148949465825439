import React from 'react';
import { useStyles } from "./Footer.css";
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getManifParams } from '../../actions/manif';
import { AppBar, Toolbar, Grid, Typography } from '@material-ui/core';
import { CustomTheme1 } from "../css/CustomTheme1.css";
import { CustomTheme2 } from "../css/CustomTheme2.css";
import { CustomTheme3 } from "../css/CustomTheme3.css";
import { CustomTheme4 } from "../css/CustomTheme4.css";
import { CustomTheme5 } from "../css/CustomTheme5.css";

const Footer = (props) => {
    const classes = useStyles();
    const manifParams = useSelector(state => state.manif);

    let classesSelected = null
    const theme1 = CustomTheme1();
    const theme2 = CustomTheme2();
    const theme3 = CustomTheme3();
    const theme4 = CustomTheme4();
    const theme5 = CustomTheme5();

    switch (props.manif.manifParams.Theme) {
        case "theme1":
            classesSelected = theme1
            break;
        case "theme2":
            classesSelected = theme2
            break;
        case "theme3":
            classesSelected = theme3
            break;
        case "theme4":
            classesSelected = theme4
            break;
        case "theme5":
            classesSelected = theme5
            break;
        default:
            break;
    }

    return (
        <AppBar position="fixed" color="primary" className={classesSelected.footerBar}>
            <Toolbar className={classes.toolBarClass}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography className={classes.alignTextLeft} variant="caption" >{new Date().getFullYear() } © Lysoft SA</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography className={classes.alignTextRight} variant="caption" >{manifParams.manifParams.versionNum}</Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getManifParams }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer)