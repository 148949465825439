const sortCollection = (collection) => {
  // Fonction de comparaison personnalisée
  const compareDatesAndAlias = (a, b) => {
    // Comparaison des dates
    const dateA = new Date(a.linkActTH_TH.DateActivite);
    const dateB = new Date(b.linkActTH_TH.DateActivite);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    // Les dates sont égales, comparer les alias
    const aliasA = a.linkActTH_TH.Alias;
    const aliasB = b.linkActTH_TH.Alias;
    if (aliasA < aliasB) {
      return -1;
    }
    if (aliasA > aliasB) {
      return 1;
    }
    // Les alias sont également égaux
    return 0;
  };

  // Tri de la collection en utilisant la fonction de comparaison personnalisée
  const sortedCollection = [...collection].sort(compareDatesAndAlias);
  return sortedCollection;
};

export default sortCollection;

export const  calculateParticipantTotalByHour = (days, participants) => {
  const res = {};

  // Parcourir la liste des jours
  Object.keys(participants).map((rowKey, i) => {
    Object.keys(days).map((day, i) => (
      days[day].map((hour, j) => {
        const hasMatchingObject = participants[rowKey].some((item) => (
          item.Date === day && item.Alias === hour
        ));
        if (!res[day]) {
          res[day] = {};
        }

        if (!res[day][hour]) {
          res[day][hour] = 0;
        }
        res[day][hour] = hasMatchingObject ? res[day][hour] + 1 : res[day][hour];
        return null;
      })
    )
    );
    return null;
  })

  return res;
}

export const countParticipantsByDateAndAlias = (data, date, alias) => {
  let count = 0;

  Object.values(data).forEach((participants) => {
    participants.forEach((participant) => {
      if (participant.Date === date && participant.Alias === alias) {
        count++;
      }
    });
  });

  return count;
}
