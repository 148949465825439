import React from 'react'
import './App.css';
import reducer from './reducers';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import LoadDatas from './pages/loadDatas/LoadDatas';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ModalProvider } from './components/general/modalContext';

const themeColor = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#388e3c",
      
    },
  },
});

const App = () => {
  let middleware = null;
  if (process.env.NODE_ENV !== 'production') {
    middleware = applyMiddleware(thunkMiddleware, logger);
  } else {
    middleware = applyMiddleware(thunkMiddleware);
  }

  const store = createStore(reducer, middleware);
  return (
    <ThemeProvider theme={themeColor}>
      <Provider store={store}>
        <ModalProvider>
          <LoadDatas className="main-theme" />
        </ModalProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
