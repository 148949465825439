import React, { useState, useEffect } from 'react'
import { useStyles } from "./ChoixDates.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Alert } from '@material-ui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import rest from '../../../rest';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ChoixDates = (props) => {
  const [showWarning, setShowWarning] = useState(false)
  const [datesDispoWishlist, setDatesDispoWishlist] = useState([])
  const classes = useStyles();
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const displayedDates = props.displayedDates;
  const setDisplayedDates = props.setDisplayedDates;
  const displayDateTxt = props.displayDateTxt;
  const setDisplayDateTxt = props.setDisplayDateTxt;
  const selectedCheckboxes = props.selectedCheckboxes;
  const setSelectedCheckboxes = props.setSelectedCheckboxes;
  const [aliasDispo, setAliasDispo] = useState([])
  const manifInfo = props.manifInfo;
  const [expanded, setExpanded] = useState('');
  const [expandedSub, setExpandedSub] = useState('');
  const matches = useMediaQuery('(max-width:720px)');

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {//pour reprendre la selection quand on revient
    setSelectedChoices(selectedCheckboxes)
  }, [selectedCheckboxes])

  useEffect(() => {
    rest.get('/activites/recupDatesDispoWishlist.json')
      .then((response) => {
        setDatesDispoWishlist(response.data)
      })
  }, [])

  useEffect(() => {
    if (datesDispoWishlist.length > 0) {

      for (let index = 0; index < datesDispoWishlist.length; index++) {
        const element = datesDispoWishlist[index];
        if (element.hasOwnProperty("AliasDispo")) {
          setAliasDispo(element.AliasDispo)
        }
      }
      // datesDispoWishlist.map((key, value) => {
      //   if (key.hasOwnProperty("AliasDispo")) {
      //     setAliasDispo(key.AliasDispo)
      //   }
      // })
      var arrayDates = [];
      datesDispoWishlist.forEach((key, value) => {
        if (datesDispoWishlist.indexOf(key.Activite) < 0) {
          arrayDates.push(key.Activite);
        }
      })
      //setDisplayedDates(arrayDates)
    }

  }, [datesDispoWishlist])

  const showNext = () => {

    let nbTrancheOk = false;
    let fullTrancheByDay = 0;
    const nbDays = (Object.keys(displayedDates).length);
    if (!manifInfo.NbMinWishlist || manifInfo.NbMinWishlist === '') manifInfo.NbMinWishlist = 0;

    for (let index = 0; index < displayedDates.length; index++) {
      const element = displayedDates[index];
      const countTrancheByDay = element.periode.split(",")
      if (countTrancheByDay.length >= manifInfo.NbTrancheMinWishlist) {
        fullTrancheByDay++;
      }
    }

    switch (Number(manifInfo.InscriptionWishlistTypeChoix)) {
      case 1:
        if (fullTrancheByDay >= manifInfo.NbMinWishlist) nbTrancheOk = true;
        break;

      default:
        // comportement par defaut - tous les jours doivent avoir le nombre d'heures indiquée dans les paramettres 
        if (fullTrancheByDay >= nbDays) nbTrancheOk = true;
        break;
    }
    if ((nbDays < manifInfo.NbMinWishlist) || (!nbTrancheOk)) {
      scrollToTop();
      setShowWarning(true);
    } else {
      setShowWarning(false);
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }


  const setDays = (dateJour, action, arraySelectedChoices, allSelect, actParent) => {
    // console.log("action", action)
    // console.log("displayedDates", displayedDates)
    var arrayDate = [...displayedDates];
    var obj = {}
    // const index = arrayDate.findIndex(arrayDate => arrayDate === dateJour);

    var exist = false;
    var indexFilteredDate = -1;
    if (arrayDate.length > 0) {
      arrayDate.filter(dates => dates.date === dateJour).forEach((filteredDates, index) => {
        exist = true
        const search = obj => obj.date === dateJour;
        indexFilteredDate = arrayDate.findIndex(search);
      })
    }

    var period = "";
    if (action === "ajouter") {
      if (allSelect === "allSelected") {
        if (!(exist)) {
          obj.date = dateJour
          //obj.dateDate=moment(dateJour,'DD.MM.YYYY').format('L');
          obj.periode = "Toute la journée"
          arrayDate.push(obj)
        } else {
          arrayDate[indexFilteredDate].periode = "Toute la journée"
        }
      } else {
        period = "";
        arraySelectedChoices.forEach((jours) => {

          var splitJour = jours.split("_");
          if (splitJour[0] === dateJour) {
            if (period.length < 1) {
              period = splitJour[1];
            } else {
              period = period + ", " + splitJour[1];
            }
          }
        })

        if (!(exist)) {
          obj.date = dateJour
          //obj.dateDate=moment(dateJour,'DD.MM.YYYY').format('L');/*moment(dateJour,'DD.MM.YYYY').format('DD.MM.YYYY')*/
          obj.periode = period
          arrayDate.push(obj)
        } else {
          arrayDate[indexFilteredDate].periode = period
        }
      }
    } else if (action === "supprimer") {
      period = "";
      arraySelectedChoices.forEach((jours) => {
        var splitJour = jours.split("_");
        if (splitJour[0] === dateJour) {
          if (period.length < 1) {
            period = splitJour[1];
          } else {
            period = period + ", " + splitJour[1];
          }
        }
      })

      if (exist) {
        // verif si periode = period puis modif period ==> ne pas delete sauf si period = ""
        //delete arrayDate[indexFilteredDate]
        if (period === "") {
          arrayDate.splice(indexFilteredDate, 1);
        } else {
          arrayDate[indexFilteredDate].periode = period;
        }
      } else {
        obj.date = dateJour
        //obj.dateDate=moment(dateJour,'DD.MM.YYYY').format('L');
        obj.periode = period
        arrayDate.push(obj)
      }
    } else if (action === "supprimerAll") {
      arrayDate.splice(indexFilteredDate, 1);
    }
    const sortedArray = arrayDate.sort((a, b) => moment(a.date, 'DD.MM.YYYY') - moment(b.date, 'DD.MM.YYYY'))

    let tmpArray = []
    for (let index = 0; index < sortedArray.length; index++) {
      tmpArray.push({ date: moment(sortedArray[index].date, 'DD.MM.YYYY').format("dddd, DD.MM.YYYY"), periode: sortedArray[index].periode })
    }


    for (let index = 0; index < tmpArray.length; index++) {
      let element = tmpArray[index].periode.split(",");
      element.sort(function (a, b) {
        let compA = parseInt(a.split(" - ")[0])
        let compB = parseInt(b.split(" - ")[0])
        return compA - compB;
      });
      for (let index = 0; index < element.length; index++) {
        element[index] = element[index] + ", "
      }
      tmpArray[index].periode = element.join("").slice(0, -2)
    }

    for (let index = 0; index < sortedArray.length; index++) {
      let element = sortedArray[index].periode.split(",");
      element.sort(function (a, b) {
        let compA = parseInt(a.split(" - ")[0])
        let compB = parseInt(b.split(" - ")[0])
        return compA - compB;
      });
      for (let index = 0; index < element.length; index++) {
        element[index] = element[index] + ", "
      }
      sortedArray[index].periode = element.join("").slice(0, -2)
    }
    // console.log("sortedArray", sortedArray)
    // console.log("tmpArray", tmpArray)
    setDisplayedDates(sortedArray)
    setDisplayDateTxt(tmpArray)

  }

  const handleChange = (e) => {
    // const removeValue = (prev) => {
    //   const tmpArray = prev

    //   tmpArray.splice(index, 1);
    //   const ckAll = splitDate[0] + "_All";
    //   const indexAll = prev.indexOf(ckAll)
    //   if (indexAll >= 0) 
    //    tmpArray.splice(indexAll)
    //    return tmpArray
    // }
    const targetName = e.target.id;
    const targetParent = e.target.dataset.parent;
    const splitDate = targetName.split("_");
    var arrayChoices = [...selectedChoices];
    var arrayDates = [...selectedDates];
    var index = selectedChoices.indexOf(targetName);
    if (index !== -1) {
      arrayChoices.splice(index, 1);
      arrayDates.splice(index, 1);
      var ckAll = splitDate[0] + "_All";
      var indexAll = selectedChoices.indexOf(ckAll)
      if (indexAll >= 0) {
        arrayChoices.splice(indexAll, 1);
        arrayDates.splice(index, 1);
      }
      setDays(splitDate[0], "supprimer", arrayChoices, "", targetParent);
      setSelectedChoices([...arrayChoices])
      setSelectedCheckboxes([...arrayChoices])
      setSelectedDates([...arrayDates])
    } else {

      // console.log("selectedChoices", selectedChoices)
      // console.log("selectedDates", selectedDates)

      setSelectedCheckboxes((prev) => [...prev, targetName])
      setSelectedChoices((prev) => [...prev, targetName])
      setSelectedDates((prev) => [...prev, splitDate[0]])
      arrayChoices.push(targetName)
      setDays(splitDate[0], "ajouter", arrayChoices, "", targetParent);
    }
  };

  // const handleChooseAll = (event) => {
  //   const sectionCheckbox = event.target;
  //   const sectionParent = sectionCheckbox.getAttribute("data-parent");
  //   const checkboxesInSection = document.querySelectorAll(`input[type="checkbox"][data-name="checkBox_${sectionParent}"]`);
  // console.log('checkboxesInSection =====>>>>', checkboxesInSection);
  //   checkboxesInSection.forEach((checkbox) => {
  //     //checkbox.checked = sectionCheckbox.checked;
  //     checkbox.setAttribute("data-parent", sectionCheckbox.getAttribute('data-parent'));
  //     handleChange({ target: checkbox }); 
  //   });
  // };

  const handleChooseAllBis = ({ target }, aliasDispo, date) => {
    const indexDate = datesDispoWishlist[1].DateString.indexOf(date)
    const dateFormated = datesDispoWishlist[1].DateDispo[indexDate]
    const tmpAlias = aliasDispo.map((alias) => `${dateFormated}_${alias}`)

    setSelectedCheckboxes((prev) => prev.filter((value) => !(tmpAlias.indexOf(value) >= 0)))
    setSelectedChoices((prev) => prev.filter((value) => !(tmpAlias.indexOf(value) >= 0)))
    setSelectedDates((prev) => prev.filter((value) => value === dateFormated))

    if (target.checked) {
      setSelectedCheckboxes((prev) => [...prev, ...tmpAlias])
      setSelectedChoices((prev) => [...prev, ...tmpAlias])
      setSelectedDates((prev) => [...prev, ...new Array(tmpAlias.length).fill(dateFormated)])
      setDays(dateFormated, "ajouter", tmpAlias, "allSelect");
    } else
      setDays(dateFormated, "supprimer", [], "");
  }


  // const handleChooseAll = (e) => {
  //   const target = e.target;
  //   const targetName = e.target.name;
  //   const targetParent = e.target.getAttribute('data-parent');
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   var array = [...selectedChoices];
  //   var arrayDates = [...selectedDates];
  //   const splitDate = targetName.split("_");

  //   var index = selectedChoices.indexOf(targetName)
  //   if (index !== -1) {
  //     selectedChoices.splice(index, 1);
  //     array.splice(index, 1);
  //     arrayDates.splice(index, 1);
  //     setDays(splitDate[0], "supprimerAll",selectedChoices, targetParent);  
  //     selectedDates.forEach((jours)  => {
  //       if(jours === splitDate[0]){
  //         var index3 = arrayDates.indexOf(splitDate[0])
  //         arrayDates.splice(index3, 1);
  //       }
  //     }) 
  //   } else {
  //     array.push(targetName)
  //     arrayDates.push(splitDate[0])
  //   }
  //   setSelectedDates([...arrayDates])

  //   var splitAlias = e.target.id.split("_")
  //   var vAliasDispo = splitAlias[2].split(",");

  //   vAliasDispo.forEach((vAlias) => {
  //     if(vAlias!=="" ){
  //       let nameAlias = splitDate[0]+"_"+vAlias
  //       var index = selectedChoices.indexOf(nameAlias)
  //       if (index !== -1 ) {
  //         if(!value){
  //           selectedChoices.splice(index, 1);
  //           array.splice(index, 1);
  //         }
  //       } else {
  //         array.push(nameAlias)
  //       }
  //     }
  //   })
  //   setSelectedChoices([...array])

  //   if(value){
  //     setDays(splitDate[0], "ajouter", array, "allSelected", targetParent);  
  //   }
  // };

  const handleSelectAllDates = (e, aliasDispo) => {
    setSelectedCheckboxes([])
    setSelectedChoices([])
    setSelectedDates([])
    setDisplayedDates([])
    setDisplayDateTxt([])
    if (e.target.checked) {
      let allDispoChecked = [];
      let allDispoForDisplay = [];
      let allDispoForDisplayDate = [];
      for (let index = 0; index < datesDispoWishlist[1].DateDispo.length; index++) {
        const element = datesDispoWishlist[1].DateDispo[index];
        allDispoForDisplay.push({ date: element, periode: "" })
        allDispoForDisplayDate.push({ date: moment(element, 'DD.MM.YYYY').format("dddd, DD.MM.YYYY"), periode: "" })
        for (let index2 = 0; index2 < aliasDispo.length; index2++) {
          const element2 = aliasDispo[index2];
          allDispoChecked.push(element + "_" + element2)
          allDispoForDisplay[index].periode += element2 + ", "
          allDispoForDisplayDate[index].periode += element2 + ", "
        }
      }
      // console.log("allDispoChecked", allDispoChecked)
      setSelectedCheckboxes(allDispoChecked)
      setSelectedChoices(allDispoChecked)
      setSelectedDates(datesDispoWishlist[1].DateDispo)


      setDisplayedDates(allDispoForDisplay)
      setDisplayDateTxt(allDispoForDisplayDate)
    }

  }

  const handleExpand = (panel, niveau) => (event, newExpanded) => {
    switch (niveau) {
      case "1":
        setExpanded(newExpanded ? panel : false);
        setExpandedSub('');
        break;
      case "2":
        setExpandedSub(newExpanded ? panel : false);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment >
      <main className={classes.content}>
        {manifInfo.TexteChoixDispo !== "" &&
          <Grid container className={classes.marginGrid}>
            <Grid item xs={12} >
              <Alert severity="warning" className={classes.alertCustom}>
                <div dangerouslySetInnerHTML={{ __html: manifInfo.TexteChoixDispo }} />
              </Alert>
            </Grid>
          </Grid>
        }
        {showWarning &&
          <Grid container className={classes.marginGrid} >
            <Grid item xs={12} className={classes.alertCustom}>
              <Alert severity="error" >
                {
                  manifInfo.Ref === "chi" ?
                    "Vous devez sélectionner au minimum " + manifInfo.NbTrancheMinWishlist + " tranches horaires par jour sur " + manifInfo.NbMinWishlist + " jours différents, en privilégiant les disponibilités pendant la manifestation, pour passer à l'étape suivante."
                    :
                    "Tu dois sélectionner au minimum " + manifInfo.NbTrancheMinWishlist + " tranches horaires par jour sur " + manifInfo.NbMinWishlist + " jours différents, en privilégiant les disponibilités pendant la manifestation, pour passer à l'étape suivante."
                }
              </Alert>
            </Grid>
          </Grid>
        }
        <Grid container >
          <Grid item xs={12} sm={7}><br />
            {datesDispoWishlist.map((key, val) =>
              key.hasOwnProperty('Activite') &&
              <div key={`div_${val}`}>
                <Accordion key={`accordion_${val}`} id={`accordion_${val}`} className={classes.accordion} square expanded={expanded === `panel${val}`} onChange={handleExpand(`panel${val}`, "1")} >
                  <AccordionSummary
                    style={{ backgroundColor: '#b7b7b7' }}
                    className={classes.accordionSummary}
                    key={`accordionsum_${val}_${key.Activite}`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="h5" component="span">
                      {key.Activite}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails key={`details_${val}_${key.Activite}`} className={classes.accordionDetail} >
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              // checked={true}
                              onChange={(e) => handleSelectAllDates(e, aliasDispo)}
                              id="selectAllDates"
                              name="selectAllDates"
                            // inputProps={{ "data-parent": key.Activite }}
                            />
                          }
                          label={"Tout sélectionner"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid container key={`grid_${val}_${key.Activite}`} spacing={2}>
                      <Grid item xs={matches ? 12 : 6}>
                        {key.DateString && key.DateString.map((ke, valu) =>
                          (valu % 2 === 0 || matches) &&
                          <div key={`1_${ke}`}>
                            <Accordion key={`subaccordion_${ke}_${key.DateString[ke]}`} square expanded={expandedSub === `panel${valu}`} onChange={handleExpand(`panel${valu}`, "2")}>
                              <AccordionSummary
                                key={`subaccordionsum_${ke}_${valu}`}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${valu}c-content`}
                              >
                                <Typography variant="h6" component="span">
                                  {key.DateString[valu]}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails key={`subdetails_${ke}_${ke + "_" + valu}`} className={`section-container-${valu}`}>
                                <FormGroup>
                                  <FormControlLabel
                                    key={`fcl_${key.DateDispo[valu]}_all`}
                                    control={
                                      <Checkbox
                                        color="primary"
                                        onChange={(e) => handleChooseAllBis(e, aliasDispo, key.DateString[valu])}
                                        name={key.DateDispo[valu] + "_All"}
                                        id={key.DateDispo[valu] + "_All"}
                                        inputProps={{ "data-parent": valu, "data-id": `checkBox_${valu}` }}
                                      />
                                    }
                                    label="Toute la journée"
                                  />
                                  {aliasDispo.map((k, val) => {

                                    return (
                                      aliasDispo[val] !== "" &&
                                      <FormControlLabel
                                        key={`fcl_${key.DateDispo[valu]}_${aliasDispo[val]}`}
                                        control={
                                          <Checkbox
                                            key={`ck_${key.DateDispo[valu]}_${aliasDispo[val]}`}
                                            color="primary"
                                            checked={selectedCheckboxes.indexOf(key.DateDispo[valu] + "_" + aliasDispo[val]) > -1}
                                            onChange={handleChange}
                                            id={key.DateDispo[valu] + "_" + aliasDispo[val]}
                                            name={key.DateDispo[valu]}
                                            inputProps={{ "data-parent": key.Activite, "data-name": `checkBox_${valu}` }}
                                          />
                                        }
                                        label={aliasDispo[val]}
                                      />
                                    )
                                  })
                                  }
                                </FormGroup>
                              </AccordionDetails>
                            </Accordion>
                            <br />
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={matches ? 12 : 6}>
                        {key.DateString && key.DateString.map((ke, valu) =>
                          (valu % 2 !== 0 && !matches) &&
                          <div key={`2_${ke}`}>
                            <Accordion key={`subaccordion_${ke}_${key.DateString[ke]}`} square expanded={expandedSub === `panel${valu}`} onChange={handleExpand(`panel${valu}`, "2")}>
                              <AccordionSummary
                                key={`subaccordionsum_${ke}_${valu}`}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${valu}c-content`}
                              >
                                <Typography variant="h6" component="span">
                                  {key.DateString[valu]}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails key={`subdetails_${ke}_${ke + "_" + valu}`}>
                                <FormGroup>
                                  {/* <FormControlLabel
                                          key={`fcl_${key.DateDispo[valu]}_all`}
                                          control={
                                            <Checkbox 
                                              color="primary" 
                                              onChange={handleChooseAll} 
                                              checked={selectedChoices.indexOf(key.DateDispo[valu]+ "_All") > -1} 
                                              name={key.DateDispo[valu] + "_All"}
                                              id={key.DateDispo[valu] + "_All_" + key.AliasDispo.toString()}
                                              inputProps={{ "data-parent": key.Activite }} 
                                            /> 
                                          }
                                          label="Toute la journée"
                                          /> */}
                                  {/*  { key.AliasDispo.map((k,val) => {
                                          const checkedValue = key.DateDispo[valu]+ "_"+key.AliasDispo[val];
                                          return(
                                            key.AliasDispo[val]!=="" &&
                                              <FormControlLabel
                                                key={`fcl_${key.DateDispo[valu]}_${key.AliasDispo[val]}`}
                                                control={
                                                  <Checkbox 
                                                    key={`ck_${key.DateDispo[valu]}_${key.AliasDispo[val]}`}
                                                    color="primary" 
                                                    checked={selectedChoices.indexOf(checkedValue) > -1} 
                                                    onChange={handleChange} 
                                                    id={key.DateDispo[valu]+ "_"+key.AliasDispo[val]}
                                                    name={key.DateDispo[valu]}
                                                    inputProps={{ "data-parent": key.Activite }} 
                                                  />
                                                }
                                                label={key.AliasDispo[val]}
                                              />
                                          )
                                        }) 
                                      } */}
                                  <FormControlLabel
                                    key={`fcl_${key.DateDispo[valu]}_all`}
                                    control={
                                      <Checkbox
                                        color="primary"
                                        onChange={(e) => handleChooseAllBis(e, aliasDispo, key.DateString[valu])}
                                        name={key.DateDispo[valu] + "_All"}
                                        id={key.DateDispo[valu] + "_All"}
                                        inputProps={{ "data-parent": valu, "data-id": `checkBox_${valu}` }}
                                      />
                                    }
                                    label="Toute la journée"
                                  />
                                  {aliasDispo.map((k, val) => {
                                    return (
                                      aliasDispo[val] !== "" &&
                                      <FormControlLabel
                                        key={`fcl_${key.DateDispo[valu]}_${aliasDispo[val]}`}
                                        control={
                                          <Checkbox
                                            key={`ck_${key.DateDispo[valu]}_${aliasDispo[val]}`}
                                            color="primary"
                                            checked={selectedCheckboxes.indexOf(key.DateDispo[valu] + "_" + aliasDispo[val]) > -1}
                                            onChange={handleChange}
                                            id={key.DateDispo[valu] + "_" + aliasDispo[val]}
                                            name={key.DateDispo[valu]}
                                            inputProps={{ "data-parent": key.Activite }}
                                          />
                                        }
                                        label={aliasDispo[val]}
                                      />
                                    )
                                  })
                                  }
                                </FormGroup>
                              </AccordionDetails>
                            </Accordion>
                            <br />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <br />
              </div>

            )
            }
          </Grid>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={3} >
            <h2 className={classes.titreDatesSelect} >Dates sélectionnées</h2>
            {displayDateTxt.map((key, value) => {
              return <div key={key.date}>
                <h3 className={classes.marginDate}>{key.date}</h3>
                <p className={classes.marginPeriode}>{key.periode}</p>
              </div>
            })}
            <Button size="small" fullWidth variant="contained" color="secondary" onClick={showNext}>
              Valider mes choix
            </Button>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    activites: state.activites,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(ChoixDates)

