import { useState } from 'react';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button } from '@material-ui/core';

const AddNewUser = ({ data, setData, idActivite, activites }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [options, setOptions] = useState([]);

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
  };

  const handleUserSearch = async (event) => {
    const searchQuery = event.target.value;

    if (searchQuery.length >= 3 && idActivite !== 0) {
      try {
        const response = await axios.get('/tableauDesAttributions/searchUserNoHour.json', {
          params: {
            search: searchQuery
          }
        });
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching user options:', error);
      }
    } else {
      setOptions([]);
    }
  };


  const handleAddUser = () => {
    if (selectedUser) {
      const updatedData = { ...data };
      let activiteNom = activites.find((item) => item.ID === idActivite)?.Nom || '';
      updatedData[`${selectedUser.Name}_${selectedUser.Firstname}_${activiteNom}`] = [{ ...selectedUser, Name_Activite: activiteNom, ID_Activite: idActivite }];
      setData(updatedData);
      setSelectedUser(null);
      console.log('Selected user:', activiteNom, data, activites);
    }
  };


  return (
    <div>
      {idActivite !== 0 && (
        <div>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.Name + ' ' + option.Firstname}
            value={selectedUser}
            noOptionsText="pas de correspondance"
            onChange={handleUserChange}
            className='newUserSelect'
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ajouter un bénévole"
                onChange={handleUserSearch}
              />
            )}
          />
          <Button onClick={handleAddUser}
            variant="contained"
            style={{ margin: 10 }}
            color="primary">Ajouter</Button>
        </div>
      )}
    </div>
  );


}
export default AddNewUser;
